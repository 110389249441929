<template>
  <v-card class="mx-auto" max-width="580" flat>
    <v-card flat>
      <v-form ref="form" v-model="valid" lazy-validation>
        <v-dialog
          v-model="showDetail"
          persistent
          max-width="550"
          @keydown.esc="close"
        >
          <v-card width="600">
            <v-card-title class="text-h5"> Commentaires </v-card-title>
            <v-card-text>
              <v-text-field
                autocomplete="off"
                ref="qte"
                class="inputPrice"
                type="number"
                dense
                v-model="editedItem.prix_reviens"
                label="Prix de reviens"
                v-if="traiter"
                :readonly="!edit"
                hide-spin-buttons
              >
              </v-text-field>
              <v-textarea
                v-model="editedItem.comment"
                :rules="[(v) => !!v || 'Commentaire obligatoire']"
                label="Commentaire"
                :readonly="!edit"
              >
              </v-textarea>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                :loading="progress"
                color="primary"
                @click="save_comment"
                v-if="edit"
              >
                Enregistrer
              </v-btn>
              <v-btn color="error" @click.stop="close"> Fermer </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-form>
    </v-card>
  </v-card>
</template>
<script>
import UPDATE_DEMANDE_DETAIL from "../graphql/Demande/UPDATE_DEMANDE_DETAIL.gql";
export default {
  props: {
    item: Object,
    edit: Boolean,
    traiter: Boolean,
    showForm: Boolean,
  },
  data() {
    return {
      valid: true,
      progress: false,
      editedItem: {},
    };
  },
  created() {
    this.editedItem = Object.assign({}, this.item);
  },
  computed: {
    showDetail() {
      return this.showForm;
    },
  },
  methods: {
    close() {
      this.$emit("close");
    },
    async maj(query, v, refresh) {
      let r;
      this.progress = true;
      await this.$apollo
        .mutate({
          mutation: query,
          variables: v,
        })
        .then((data) => {
          if (refresh) this.$store.dispatch("Changed", true);
          r = data.data;
          this.progress = false;
        })
        .catch((error) => {
          this.progress = false;
          this.snackbar_text = error.message;
          this.snackbar_color = "error";
          this.snackbar = true;
        });
      return r;
    },
    async save_comment() {
      if (this.$refs.form.validate()) {
        let v = {
          demandedetail: {
            id: this.editedItem.id,
            prix_reviens: parseFloat(this.editedItem.prix_reviens),
            comment: this.editedItem.comment,
            write_uid: this.$store.state.me.id,
          },
        };
        await this.maj(UPDATE_DEMANDE_DETAIL, v);
        this.$store.dispatch("Changed", true);
        this.comment = null;
        this.$emit("close");
      }
    },
  },
};
</script>
